<template>
  <div class="home">
    <v-row align="" class="mt-10" style="height: 100vh">
      <v-col class="center" cols="12">
        <h3 class="text-uppercase primary--text">Sorry :(</h3>
        <br>
        <p>De pagina die je zocht kon niet worden gevonden.</p>
        <br>
        <v-btn
          class="secondary"
          rounded
          x-large
          elevation="0"
          href="https://www.saveachildsheart.nl"
        >
          Naar de homepage
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
};
</script>
