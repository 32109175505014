<template>
  <div>
    <div v-if="publication.page_id">
      <v-container>
        <v-row class="mt-12">
          <v-col cols="3" class="flex-grow-1 flex-shrink-0 desktop-only">
            <v-img :src="require('@/assets/photos_left.png')"> </v-img>
          </v-col>
          <v-col colls="8" class="flex-grow-1 flex-shrink-1 center">
            <h5 class="font-weight-medium text-uppercase secondary--text">
              {{ publication.subheading }}
            </h5>
            <h2 class="font-weight-bold text-uppercase secondary--text mb-4">
              {{ publication.title }}
            </h2>
            <p class="txt">{{ publication.description }}</p>
            <div class="w80" v-if="!!fundraiserAmount">
              <v-progress-linear
                v-model="fundraiserProgressAsPercentage"
                color="secondary"
                background-color="grey lighten-3"
                height="30"
                class="progressbar mt-12 mb-4"
              ></v-progress-linear>
              <div class="txt mt-6 mb-6">
                € {{ fundraiserAmount.amount }} van €{{
                  publication.target_amount
                }}
                opgehaald
              </div>
            </div>
            <a href="#donate" class="text-decoration-none">
              <v-btn
                class="secondary mt-4"
                rounded
                x-large
                elevation="0"
              >
                <v-img
                  :src="require('@/assets/heart.svg')"
                  contain
                  height="23"
                  class="mr-2"
                />Doneer nu
              </v-btn>
            </a>
            <v-img
              :src="require('@/assets/ecg_red.svg')"
              contain
              height="145"
              class="mt-10 mt-lg-16"
            />
          </v-col>
          <v-col cols="3" class="flex-grow-1 flex-shrink-0 desktop-only">
            <v-img :src="require('@/assets/photos_right.png')"> </v-img>
          </v-col>
        </v-row>
        <v-row class="mt-12" align="center">
          <!-- 1/4 -->
          <v-col class="center pa-0" cols="12" md="6">
            <v-img
              :src="publication.photo_one[0].cover"
              lazy-src="@/assets/loading.jpg"
              class="rounded-corners-left"
              max-height="620"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-col class="center pa-10 pb-0 pa-md-10" cols="12" md="6">
            <v-img
              :src="require('@/assets/small_big_heart.svg')"
              contain
              height="48"
              class="mb-5"
            />
            <h4 class="font-weight-medium text-uppercase secondary--text">
              {{ publication.text_area_one_title }}
            </h4>
            <div class="txt">{{ publication.text_area_one_text }}</div>
          </v-col>
        </v-row>
        <v-row align="center">
          <!-- 2/4 -->
          <v-col class="center pa-10 pt-0 pa-md-10 pt-12" cols="12" md="6">
            <v-img
              :src="require('@/assets/big_hearts.svg')"
              contain
              height="48"
              class="mb-5"
            />
            <h4 class="font-weight-medium text-uppercase secondary--text">
              {{ publication.text_area_two_title }}
            </h4>
            <div class="txt">{{ publication.text_area_two_text }}</div>
            <a href="#donate" class="text-decoration-none">
              <v-btn
                class="secondary mt-4"
                rounded
                x-large
                elevation="0"
              >
                <v-img
                  :src="require('@/assets/heart.svg')"
                  contain
                  height="23"
                  class="mr-2"
                />Doneer nu
              </v-btn>
            </a>
          </v-col>
          <v-col class="center pa-0" cols="12" md="6">
            <v-img
              :src="publication.photo_two[0].cover"
              lazy-src="@/assets/loading.jpg"
              class="rounded-corners-right"
              max-height="600"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <v-row align="center">
          <!-- 3/4 -->
          <v-col class="center pa-0 desktop-only" cols="12" md="6">
            <v-img
              :src="require('@/assets/kid1.jpg')"
              lazy-src="@/assets/loading.jpg"
              class="rounded-corners-left"
              max-height="600"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-col class="center pa-10 pb-0 pa-md-10 pt-12" cols="12" md="6">
            <v-img
              :src="require('@/assets/heart_plus.svg')"
              contain
              height="48"
              class="mb-5"
            />
            <h4 class="font-weight-medium text-uppercase secondary--text">
              Onze missie
            </h4>
            <div class="txt">
              Sinds 1995 heeft Save a Child’s Heart al meer dan 6000 kinderen gered met een aangeboren hartafwijking. In de afgelopen 25 jaar is de capaciteit flink toegenomen. Van 48 kinderen per jaar in 1996 naar al 360 kinderen in 2021. Ongeveer 50% van de kinderen komt uit Palestijnse gebieden, Jordanië, Irak en Marokko, meer dan 40% komt uit Afrika en de andere kinderen komen uit Azië, Oost-Europa en Amerika. 
            </div>
          </v-col>
        </v-row>
        <v-row align="center">
          <!-- 4/4 -->
          <v-col class="center pa-10 pt-12" cols="12" md="6" id="donate">
            <h4 class="font-weight-medium text-uppercase secondary--text">
              {{ publication.cta_text }}
            </h4>
            <!-- <div class="txt">[donation form]</div> -->
            <Donationform @submitDonation="submitDonation($event)" />
          </v-col>
          <v-col class="center pa-0" cols="12" md="6">
            <v-img
              :src="require('@/assets/kid2.jpg')"
              lazy-src="@/assets/loading.jpg"
              class="rounded-corners-right"
              max-height="700"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <v-img
          :src="require('@/assets/ecg_red.svg')"
          contain
          height="145"
          class="mt-10 mb-3"
        />
      </v-container>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import axios from "axios";
import Donationform from "../components/DonationForm.vue";
// @ is an alias to /src

export default {
  name: "Home",
  components: {
    Donationform,
  },
  data() {
    return {
      publication: {},
      fundraiserAmount: {},
      id: '',
    };
  },
  computed: {
    fundraiserProgressAsPercentage() {
      const percentage =
        (this.fundraiserAmount.amount * 100) / this.publication.target_amount;
      return percentage;
    },
  },

  mounted: async function () {
    this.id = this.$route.params.id;
    const request = await this.$prepr
      .graphqlQuery(
        `{
          DonationPage( id : "${this.id}" ) {
              page_id
              title
              subheading
              description
              target_amount
              text_area_one_title
              text_area_one_text
              photo_one {
                  _id,
                  cover : url(width: 1000),
              }
              text_area_two_title
              text_area_two_text
              photo_two {
                  _id,
                  cover : url(width: 1000),
              }
              cta_text
          }
        }`
      )
      .fetch();

    this.publication = request?.data?.DonationPage;
    if (!this.publication) {
      this.$router.push('/404')
      return
    }

    try {
      const response = await axios.get(
        process.env.VUE_APP_PLATFORM_URL + "payments/fundraisers/" + this.id
      );
      this.fundraiserAmount = response.data;
    } catch (e) {
      this.errors.push(e);
    }
  },
  methods: {
    async submitDonation($event) {
      const createPayment = {
        id: this.id,
        amount: $event.amount,
        currency: "EUR",
        description: this.publication.title,
        email: $event.email,
        name: $event.name,
        message: $event.message,
        redirectUrl: "https://www.saveachildsheart.nl/bedankt-voor-je-donatie",
      };
      try {
        const response = await axios.post(
          process.env.VUE_APP_PLATFORM_URL + "payments/fundraisers",
          createPayment
        );
        if (response.status === 200) {
          window.location.href = response.data.checkoutUrl;
        } else {
          // Do error handling when donation is not successful
        }
      } catch (e) {
        this.errors.push(e);
      }
    },
  },
};
</script>
