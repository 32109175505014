<template>
  <div>
    <v-container>
      <v-row class="mt-12">
        <v-col cols="3" class="flex-grow-1 flex-shrink-0 desktop-only">
          <v-img :src="require('@/assets/photos_right.png')"> </v-img>
        </v-col>
        <v-col colls="8" class="flex-grow-1 flex-shrink-1 center">
          <div class="small-container">
            <v-img
              :src="require('@/assets/heart_red_big.svg')"
              contain
              height="72"
              class="mb-8"
            />
            <h2 class="font-weight-bold text-uppercase secondary--text mb-4">
              Doneer nu
            </h2>
            <p class="txt-m">Bijdragen van donateurs en fondsen helpen ons om kinderen in nood te helpen. </p>
            <div class="w80">
              <div class="txt mt-6 mb-6">
                Save a Child's Heart zorgt voor de behandeling van kinderen met levensbedreigende
                hartafwijkingen ongeacht hun afkomst, religie, nationaliteit of financiële status.
              </div>
            </div>
            <div class="pa-4 pa-md-16">
              <Donationform />
            </div>
          </div>
        </v-col>
        <v-col cols="3" class="flex-grow-1 flex-shrink-0 desktop-only">
          <v-img :src="require('@/assets/photos_left.png')"> </v-img>
        </v-col>
      </v-row>
      <!-- <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="name"
          :counter="10"
          :rules="nameRules"
          label="Je naam"
          required
        ></v-text-field>
        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="Je e-mailadres"
          required
        ></v-text-field>
        <v-select
          v-model="select"
          :items="items"
          :rules="[(v) => !!v || 'Item is required']"
          label="Whoopie!"
          required
        ></v-select>
        <v-checkbox
          v-model="checkbox"
          color="secondary"
          :rules="[(v) => !!v || 'You must agree to continue!']"
          label="Weten wat er met je donatie gebeurt? "
          required
        ></v-checkbox>
        <v-btn color="secondary" rounded x-large @click="validate">
          Doneer nu
        </v-btn>
      </v-form> -->
    </v-container>
  </div>
</template>

<script>
import Donationform from "../components/DonationForm.vue";

export default {
  name: "PaymentPage",
  components: {
    Donationform,
  },
}
</script>

<style></style>
