import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/main.css";

import { PreprPlugin } from "@preprio/vuejs-sdk";
import vuetify from "./plugins/vuetify";
import axios from "axios";


axios.defaults.headers.common["ApiKey"] = process.env.VUE_APP_API_KEY;

Vue.use(PreprPlugin, {
  token: "83f2839acb323a934b857be69d17e144e3ad37a6795875a004a0acc841b6fdcb",
  baseUrl: "https://graphql.prepr.io/graphql",
  timeout: 4000,
  userId: null,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
