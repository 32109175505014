import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Campaign from "../views/Campaign.vue";
import DonationPage from "../views/DonationPage.vue";
import PaymentPage from "../views/PaymentPage.vue";
import NotFound from "../views/NotFound.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/donationpages/:id", // http://localhost:8080/donationpages/1882b576-fb47-44fc-8ad0-177416146ef4
    name: "DonationPage",
    component: DonationPage,
  },
  {
    path: "/campaign/:id", // http://localhost:8080/campaign/f9790d64-8756-461f-992d-428aca14ceb1
    name: "Campaign",
    component: Campaign,
  },
  {
    path: "/paymentpage",
    name: "PaymentPage",
    component: PaymentPage,
  },
  {
    path: "/*",
    name: "404",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
