<template>
  <div>
    <v-parallax
      height="500"
      v-if="publication.main_photo"
      lazy-src="@/assets/loading.jpg"
      :src="publication.main_photo[0].cover"
      align="center"
      class="mt-2 overlay"
    >
      <div class="overlay-blue center-vertical">
        <div class="overlay-inner">
          <p class="white--text mb-0">{{ publication.description }}</p>
          <h2 class="font-weight-bold text-uppercase white--text">
            {{ publication.page_id }}
          </h2>
          <div v-if="!!campaignAmount">
            <div class="w80">
              <v-progress-linear
                v-model="campaignProgressAsPercentage"
                color="white"
                height="30"
                class="progressbar mt-3 mb-4"
              ></v-progress-linear>
            </div>
            <p class="white--text mt-6 mb-6">
              € {{ campaignAmount.amount }} van €{{
                publication.target_amount
              }}
              opgehaald
            </p>
          </div>
          <div>
            <!-- TODO -->
            <!-- <v-btn href="#" class="primary mt-4" rounded x-large elevation="0">
              <v-img
                :src="require('@/assets/heart.svg')"
                contain
                height="23"
                class="mr-2"
                transition="none"
              />{{ publication.cta_button }}
            </v-btn> -->
          </div>
        </div>
      </div>
    </v-parallax>
    <div align="center" class="mt-16">
      <h4 class="font-weight-bold text-uppercase primary--text">
        {{ publication.team_members_subtitle }}
      </h4>
      <h3 class="font-weight-bold text-uppercase primary--text">
        {{ publication.team_members_title }}
      </h3>
      <v-img
        :src="require('@/assets/ecg_blue.svg')"
        contain
        height="145"
        class="mt-10"
      />
    </div>
    <!-- <v-img :src="publication.main_photo[0].cover" width="300px"
      gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
    >
    </v-img> -->
    <v-row>
      <v-col class="center mt-10 mb-10" cols="12">
        <div v-if="donationPages">
          <v-container>
            <v-row>
              <template v-for="item in donationPages">
                <FundraiserTile
                  :page="item"
                  :key="item._id"
                />
              </template>
            </v-row>
            <!-- <p class="mt-10">Example teamMemberProgressAsPercentage: {{ teamMemberProgressAsPercentage(10, 400) }}</p> -->
          </v-container>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
// @ is an alias to /src
import axios from "axios";
import FundraiserTile from "../components/FundraiserTile.vue";

export default {
  name: "Campaign",
  components: {
    FundraiserTile,
  },
  data() {
    return {
      publication: {},
      donationPages: {},
      campaignAmount: {},
      fundraiserAmount: {},
    };
  },

  methods: {
    navigate(id) {
      this.$router.push({ name: "DonationPage", params: { id } });
    },
  },

  computed: {
    campaignProgressAsPercentage() {
      const percentage =
        (this.campaignAmount.amount * 100) / this.publication.target_amount;
      return percentage;
    },
  },

  mounted: async function () {
    const id = this.$route.params.id;
    const request = await this.$prepr
      .graphqlQuery(
        `{
        Campaign( id : "${id}" ) {
            description
            page_id
            target_amount
            cta_button
            main_photo {
              cover : url(width: 1900),
            }
            team_members_subtitle
            team_members_title
        },
    }`
      )
      .fetch();

    this.publication = request?.data?.Campaign;
    if (!this.publication) {
      this.$router.push('/404')
      return
    }

    const donationPages = await this.$prepr
      .graphqlQuery(
        `{
        DonationPages( where : { campaign : { _id_any : ["${id}"]}  }, limit: null ) {
            items {
                _id
                page_id
                title
                subheading
                description
                target_amount
                text_area_one_title
                text_area_one_text
                photo_one {
                  _id,
                  cover : url(width: 1000),
                }
                text_area_two_title
                text_area_two_text
                photo_two {
                  _id,
                  cover : url(width: 1000),
                }
                cta_text
            }
        },
    }`
      )
      .fetch();

    this.donationPages = donationPages.data.DonationPages?.items;

    try {
      const response = await axios.get(
        process.env.VUE_APP_PLATFORM_URL + "payments/campaigns/" + id
      );
      this.campaignAmount = response.data;
    } catch (e) {
      console.log(e)
    }

    // console.log(this.donationPages);
    // console.log("campaignAmount", this.campaignAmount);
  },
};
</script>
