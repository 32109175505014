import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        white: "#FFFFFF",
        primary: "#35A5E6",
        secondary: "#E63543",
        gray: "#777777",
        lightgray: "#EBEBEB",
      },
    },
  },
});
