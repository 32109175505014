<template>
    <v-form @submit.prevent="submit">
      <v-btn-toggle
        v-model="selectedAmount"
        background-color="white"
        borderless
        rounded
        class="mr-2"
      >
        <div
          v-for="amount in donationOptions"
          :key="amount"
          class="pl-1 pr-1"
        >
          <v-btn
              active-class="active-btn"
              :value="amount"
              rounded
              class="inactive-btn"
              elevation="0"
          >
            € {{ amount }}
          </v-btn>
        </div>
      </v-btn-toggle>
      <v-text-field
        v-model="selectedAmount"
        required
        rounded
        height="50"
        type="number"
        color="secondary"
        placeholder="Te doneren bedrag"
        background-color="lightgray"
      >
      </v-text-field>
      <v-text-field
        v-model="name"
        :error-messages="nameErrors"
        @input="$v.name.$touch()"
        @blur="$v.name.$touch()"
        required
        rounded
        class="pa-0 ma-0"
        height="50"
        color="secondary"
        placeholder="Je naam"
        background-color="lightgray"
      >
      </v-text-field>
      <v-text-field
        v-model="email"
        :error-messages="emailErrors"
        @blur="$v.email.$touch()"
        required
        rounded
        class="pa-0 ma-0"
        height="50"
        color="secondary"
        placeholder="Je e-mailadres"
        background-color="lightgray"
      >
      </v-text-field>
      <v-text-field
        v-model="message"
        :error-messages="messageErrors"
        @input="$v.message.$touch()"
        @blur="$v.message.$touch()"
        rounded
        class="pa-0 ma-0 pb-4"
        height="50"
        color="secondary"
        placeholder="Persoonlijke boodschap - optioneel"
        background-color="lightgray"
        :counter="100"
      >
      </v-text-field>
      <h5 class="txt secondary--text">
        Weten wat er met je donatie gebeurt?
      </h5>
      <div class="txt-s mb-2">
        Ik geef Save a Child's Heart Nederland toestemming om mij een aantal keer per jaar per e-mail te benaderen.
      </div>
      <div v-if="selectedAmount === undefined || selectedAmount < 1 || selectedAmount === NaN">
        <v-alert
          type="info"
          color="primary"
          class="mt-5"
        >
        Selecteer hierboven een bedrag
        </v-alert>
      </div>
      <v-btn
        :disabled="formInvalid"
        class="secondary mt-4"
        rounded
        x-large
        @click="submitDonation"
        elevation="0"
        v-if="selectedAmount > 0 && selectedAmount !== undefined && selectedAmount !== NaN"
      >
        <v-img
          :src="require('@/assets/heart.svg')"
          contain
          height="23"
          width="23"
          class="mr-2"
          transition="none"
        />Doneer € {{ selectedAmount || '?' }}
      </v-btn>
      <v-img
        :src="require('@/assets/cards.svg')"
        contain
        height="30"
        class="mt-8"
      />
    </v-form>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'

export default {
  name: "DonationForm",
  components: {},
  mixins: [validationMixin],
  validations: {
    name: { required, maxLength: maxLength(30) },
    email: { required, email },
    message: { maxLength: maxLength(100) },
  },
  data() {
    return {
      selectedAmount: 50,
      errors: [],
      name: '',
      email: '',
      message: '',
      donationOptions: [
        20,
        50,
        100,
        500,
      ],
      data: {
        name: '',
        email: '',
        amount: '',
        message: '',
      },
    };
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength && errors.push('Je naam mag maximaal 30 tekens bevatten')
      !this.$v.name.required && errors.push('Je naam is vereist')
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push('Voer een correct e-mailadres in')
      !this.$v.email.required && errors.push('Je e-mailadres is vereist')
      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.maxLength && errors.push('Je persoonlijke boodschap mag maximaal 100 karakters zijn')
      return errors;
    },
    formInvalid() {
      if (this.$v) {
        return this.$v.$invalid;
      } else return true;
    },
  },
  methods: {
    submitDonation() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.data = {
        name: this.name,
        email: this.email,
        amount: this.selectedAmount,
        message: this.message,
      };
      this.$emit('submitDonation', this.data);
    },
  },
  emits: ['submitDonation']
};
</script>

<style scoped>

</style>
