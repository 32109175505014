<template>
    <v-col :key="page._id" cols="12" md="6">
      <div
        class="pa-0 black rounded-corners fill-height pointer"
        elevation="0"
      >
        <v-img
          :src="page.photo_one[0].cover"
          max-width="100%"
          max-height="400"
          min-height="400"
          lazy-src="@/assets/loading.jpg"
          class="rounded-corners fill-height"
          @click="navigate(page._id)"
        >
          <div class="overlay-dark center-bottom pb-6 w100">
            <div class="w100">
              <h2 class="text-uppercase white--text">
                {{ page.title }}
              </h2>
              <div class="w80">
                <v-progress-linear
                  :value="
                    teamMemberProgressAsPercentage(
                      fundraiserAmount.amount,
                      page.target_amount
                    )
                  "
                  color="white"
                  height="30"
                  class="progressbar mt-3 mb-4"
                ></v-progress-linear>
              </div>
              <p class="white--text mt-6 mb-6">
                € {{ fundraiserAmount.amount }} van €{{ page.target_amount }} opgehaald
                <!-- {{ page._id }} -->
              </p>
            </div>
          </div>
        </v-img>
      </div>
    </v-col>
</template>

<script>
import axios from "axios";

export default {
  name: "FundraiserTile",
  props: {
    page: {
      _id: String,
      title: String,
      photo_one: String,
      target_amount: String,
    },
  },
  components: {},
  data() {
    return {
      fundraiserAmount: {},
    };
  },

  methods: {
    teamMemberProgressAsPercentage(raised, target) {
      const percentage = (raised * 100) / target;
      return percentage;
    },
    navigate(id) {
      this.$router.push({ name: "DonationPage", params: { id } });
    },
  },

  mounted: async function () {
    try {
      const responseFundraiser = await axios.get(
        process.env.VUE_APP_PLATFORM_URL + "payments/fundraisers/" + this.page._id
      );
      this.fundraiserAmount = responseFundraiser.data;
    } catch (e) {
      this.errors.push(e);
    }
  },
};
</script>

<style scoped></style>
