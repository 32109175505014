<template>
  <div class="home">
    <v-row align="" class="mt-10" style="height: 100vh">
      <v-col class="center" cols="12">
        <v-btn
          class="primary"
          rounded
          x-large
          elevation="0"
          to="/campaign/f9790d64-8756-461f-992d-428aca14ceb1"
        >
          View active campaign
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  data() {
    return {
      publication: {},
    };
  },

  mounted: async function () {

    if (location.hostname === "localhost") {
      alert("It's a local server!");
    }
    else {
      window.location.href = "https://www.saveachildsheart.nl/";
    };

    this.publication = await this.$prepr
      .graphqlQuery(
        `{
        DonationPages {
            items {
                page_id
                title
                subheading
                description
                target_amount
                text_area_one_title
                text_area_one_text
                photo_one {
                  _id,
                  cover : url(width: 1000),
                }
                text_area_two_title
                text_area_two_text
                photo_two {
                  _id,
                  cover : url(width: 1000),
                }
                cta_text
            }
        },
    }`
      )
      .fetch();
  },
};
</script>
