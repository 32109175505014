<template>
  <v-app>
    <v-main>
      <v-navigation-drawer
        right
        v-model="sidebar"
        app
        class="center pt-4"
        v-bind:width="600"
      >
        <v-list>
          <v-list-item link href="https://www.saveachildsheartnederland.nl/">
            <v-list-item-content>
              <v-list-item-title> Home </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="item in menuItems"
            :key="item.title"
            :href="item.path"
            link
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-btn
          href="https://www.saveachildsheart.nl/kom-in-actie/doneer"
          class="secondary mt-4"
          rounded
          x-large
          elevation="0"
        >
          <v-img
            :src="require('@/assets/heart.svg')"
            contain
            height="23"
            class="mr-2"
          />Doneer nu
        </v-btn>
      </v-navigation-drawer>
      <v-toolbar elevation="0" class="mt-2">
        <span class="hidden-sm-and-up"> </span>
        <v-toolbar-title>
          <a href="https://www.saveachildsheart.nl/" tag="span" style="cursor: pointer">
            <img src="../public/logo.svg" width="200px" alt="" />
          </a>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <span class="hidden-sm-and-up">
          <div @click="sidebar = !sidebar">
            <v-icon> mdi-menu </v-icon>
          </div>
        </span>
        <v-toolbar-items class="hidden-xs-only">
          <v-btn
            elevation="0"
            color="white gray--text text-s"
            class="pa-2"
            v-for="item in menuItems"
            :key="item.title"
            :href="item.path"
          >
            {{ item.title }}
          </v-btn>
        </v-toolbar-items>
        <v-btn
          href="https://www.saveachildsheart.nl/kom-in-actie/doneer"
          class="secondary ml-6"
          rounded
          x-large
          elevation="0"
        >
          <v-img
            :src="require('@/assets/heart.svg')"
            contain
            height="23"
            class="mr-2"
          />Doneer nu
        </v-btn>
      </v-toolbar>
      <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <router-view />
      </transition>
      <v-container>
        <v-divider></v-divider>
        <v-row class="mt-6 mb-4">
          <v-col cols="11">
            <div class="font-weight-medium">
              Copyright © {{ new Date().getFullYear() }} Stichting Save a
              Child's Heart Nederland.
            </div>
            <div>Alle rechten voorbehouden.</div>
          </v-col>
          <v-col class="center" cols="1">
            <!-- right -->
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      appTitle: "Awesome App",
      sidebar: false,
      prevHeight: 0,
      menuItems: [
        {
          title: "Onze missie",
          path: "https://www.saveachildsheart.nl/onze-missie",
          icon: "",
        },
        {
          title: "Kom in actie",
          path: "https://www.saveachildsheart.nl/kom-in-actie",
          icon: "",
        },
        {
          title: "Over ons",
          path: "https://www.saveachildsheart.nl/over-ons",
          icon: "",
        },
        {
          title: "Nieuws",
          path: "https://www.saveachildsheart.nl/nieuws",
          icon: "",
        },
        {
          title: "Evenementen",
          path: "https://www.saveachildsheart.nl/evenementen",
          icon: "",
        },
      ],
    };
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800&display=swap");

.v-application {
  font-family: "Montserrat", sans-serif;
}
</style>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-duration: .3s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
  min-height: 100vh;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
  min-height: 100vh;
}

h1 {
  font-size: clamp(55px, 10vw, 140px); // min 40px, max 120px, dynamic 10vw
  line-height: 0.9;
}

h2 {
  font-size: 34px;
}

h3 {
  font-size: clamp(20px, 4vw, 35px);
  line-height: 1;
}

p {
  font-size: 20px;
}

.center {
  text-align: center;
}

.rounded {
  border-radius: 50px;
}

@media only screen and (max-width: 600px) {
  h2 {
    font-size: 26px;
  }
  p {
    font-size: 17px;
  }
}
</style>
